import React, { Component } from "react";
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';

class CityRedirect extends Component {
    componentWillMount() {
        const { previousStep, steps } = this.props;
        const { metadata = {} } = previousStep;

        this.props.triggerNextStep({ trigger: steps.verticals.value });
    }

    render() {
        return null;
    }
}


class HeaderChatbot extends Component {

    constructor(props) {
        super(props);

        this.state = {
            search: '',
            opened: true,
        };

    }

    render() {

        return (
            <React.Fragment>
                <div class="rsc-header">
                    <h2 class="rsc-header-title">BeWise Chat Support</h2>
                    <span class="rsc-header-close-button" onClick={() => {
                        this.props.onChatClose();
                        console.log('close clicked', this.props)
                    }}>x</span>
                </div>
            </React.Fragment >
        );

    }
}

class SearchResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            opened: true,
        };
    }

    componentWillMount() {
        const { steps } = this.props;
        const { search } = steps;

        this.setState({ search });
    }

    render() {
        const { search } = this.state;
        const fmtstring = search.value.toLowerCase();
        if (fmtstring == 'article' || fmtstring == 'articles' || fmtstring == 'news') {
            return (
                <div style={{ width: '100%' }}>
                    <p>We have found article page you can visit, please click <a href={"/bengaluru/community-support/articles-blogs"}>here</a>.</p>
                </div>
            );
        }
        else {
            return (
                <div style={{ width: '100%' }}>
                    <h3>Success</h3>
                    <p>Here you can <a href={"/search/" + search.value}>click</a> to see the result.</p>
                </div>
            );
        }
    }
}


class TuitionResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tuitionsoptions: '',
            cityoptions: ''
        };
    }

    componentWillMount() {
        const { steps } = this.props;
        const { tuitionsoptions, cityoptions } = steps;

        this.setState({ tuitionsoptions, cityoptions });
    }

    render() {
        const { tuitionsoptions, cityoptions } = this.state;
        return (
            <div style={{ width: '100%' }}>
                <a target="_blank" href={"/" + cityoptions.value + "/tuitions/" + tuitionsoptions.value}>view result</a>
            </div>
        );
    }
}

class SchoolResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            schooloptions: '',
            cityoptions: ''
        };
    }

    componentWillMount() {
        const { steps } = this.props;
        const { schooloptions, cityoptions } = steps;

        this.setState({ schooloptions, cityoptions });
    }

    render() {
        const { schooloptions, cityoptions } = this.state;
        return (
            <div style={{ width: '100%' }}>
                <a target="_blank" href={"/" + cityoptions.value + "/schools/" + schooloptions.value}>view result</a>
            </div>
        );
    }
}

class LearningCampResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            learningcampoptions: '',
            cityoptions: ''
        };
    }

    componentWillMount() {
        const { steps } = this.props;
        const { learningcampoptions, cityoptions } = steps;

        this.setState({ learningcampoptions, cityoptions });
    }

    render() {
        const { learningcampoptions, cityoptions } = this.state;
        return (
            <div style={{ width: '100%' }}>
                <a target="_blank" href={"/" + cityoptions.value + "/learning-camps/" + learningcampoptions.value}>view result</a>
            </div>
        );
    }
}

class CommunityResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            communityoptions: '',
            cityoptions: ''
        };
    }

    componentWillMount() {
        const { steps } = this.props;
        const { communityoptions, cityoptions } = steps;

        this.setState({ communityoptions, cityoptions });
    }

    render() {
        const { communityoptions, cityoptions } = this.state;
        return (
            <div style={{ width: '100%' }}>
                <a target="_blank" href={"/" + cityoptions.value + "/community-support/" + communityoptions.value}>view result</a>
            </div>
        );
    }
}

class KidsplayareaResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            kidsplayareaoptions: '',
            cityoptions: ''
        };
    }

    componentWillMount() {
        const { steps } = this.props;
        const { kidsplayareaoptions, cityoptions } = steps;

        this.setState({ kidsplayareaoptions, cityoptions });
    }

    render() {
        const { kidsplayareaoptions, cityoptions } = this.state;
        return (
            <div style={{ width: '100%' }}>
                <a target="_blank" href={"/" + cityoptions.value + "/kids-play-area/" + kidsplayareaoptions.value}>view result</a>
            </div>
        );
    }
}



class BirthDayResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            birthdayoptions: '',
            cityoptions: ''
        };
    }

    componentWillMount() {
        const { steps } = this.props;
        const { birthdayoptions, cityoptions } = steps;

        this.setState({ birthdayoptions, cityoptions });
    }

    render() {
        const { birthdayoptions, cityoptions } = this.state;
        return (
            <div style={{ width: '100%' }}>
                <a target="_blank" href={"/" + cityoptions.value + "/birthday-event/" + birthdayoptions.value}>view result</a>
            </div>
        );
    }
}




class AfterSchoolResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            afterschooloptions: '',
            cityoptions: ''
        };
    }

    componentWillMount() {
        const { steps } = this.props;
        const { afterschooloptions, cityoptions } = steps;

        this.setState({ afterschooloptions, cityoptions });
    }

    render() {
        const { afterschooloptions, cityoptions } = this.state;
        return (
            <div style={{ width: '100%' }}>
                <a target="_blank" href={"/" + cityoptions.value + "/after-school-activity/" + afterschooloptions.value}>view result</a>
            </div>
        );
    }
}

class PreSchoolResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            preschooloptions: '',
            cityoptions: ''
        };
    }

    componentWillMount() {
        const { steps } = this.props;
        const { preschooloptions, cityoptions } = steps;

        this.setState({ preschooloptions, cityoptions });
    }

    render() {
        const { preschooloptions, cityoptions } = this.state;
        return (
            <div style={{ width: '100%' }}>
                <a target="_blank" href={"/" + cityoptions.value + "/pre-schools/" + preschooloptions.value}>view result</a>
            </div>
        );
    }
}


class About extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    render() {
        return (
            <div style={{ width: '100%' }} className="aboutinchatbox">
                <div style={{ textAlign: 'center' }}>
                    <img src={"https://partner.bewise.in/assets/images/bewiselogo.png"} width="100px" />
                </div>
                <p>BeWise was born because we became engrossed with the idea of simplifying the parental experience of making holistic decisions about their child’s development journey.

                </p>
                <a href="/about">view more on this</a>
            </div>
        );
    }
}

class StoreMessage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            ip: ''
        };
    }

    componentWillMount() {
        const { params } = this.props;
        console.log(params.message);
        const res = axios.get('https://geolocation-db.com/json/')
        console.log(res.data);

    }


}

class ChatBotNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            ip: ''
        };
    }

    componentDidMount(){
        $(".rsc-float-button").detach();
    }
    render() {

        // all available props
        const theme = {
            background: '#E5F3F6',
            headerBgColor: '#F2F9FA',
            headerFontColor: '#000',
            headerFontSize: '14px',
            botBubbleColor: '#fff',
            botFontColor: '#000',
            botFontSize: '12px',
            userBubbleColor: '#BCD9C9',
            userFontColor: '#000',
            userFontSize: '12px',

        };

        const config = {
            width: "300px",
            height: "372px",
            floating: true
        };
        const steps = [
            {
                id: "Greet",
                message: "Welcome to BeWise chat support. What information are you looking for today?",
                trigger: "verticals",
                delay: 1000
            },
            /*
            {
              id: 'Done',
              message: 'Hello World',
              end: true,
            },
            */
            {
                id: 'verticals',
                options: [
                    { value: 'school', label: 'Schools', trigger: 'cityselection' },
                    { value: 'preschool', label: 'Pre Schools', trigger: 'cityselection' },
                    { value: 'tuitions', label: 'Tuitions', trigger: 'cityselection' },
                    { value: 'afterschool', label: 'After School Activites', trigger: 'cityselection' },
                    { value: 'kidsplayarea', label: 'Kids Play Area', trigger: 'cityselection' },
                    { value: 'birthdayevents', label: 'Birthday Events', trigger: 'cityselection' },
                    { value: 'learningcamps', label: 'Learning Camps', trigger: 'cityselection' },
                    { value: 'communitysupport', label: 'Community Support', trigger: 'cityselection' },
                    /* { value: 'about', label: 'about BeWise', trigger: 'about'}, 
                     { value: 1000, label: 'search', trigger: '999' },*/
                ],
            },

            {
                id: 'cityselection',
                message: 'Please select city',
                trigger: 'cityoptions',
                delay: 1000
            },
            {
                id: 'cityoptions',
                options: [
                    { value: 'bengaluru', label: 'Bengaluru', trigger: 'processcity' },
                    { value: 'chennai', label: 'Chennai', trigger: 'processcity' },
                    { value: 'hosur', label: 'Hosur', trigger: 'processcity' }
                ],
            },


            {
                id: "processcity",
                replace: true,
                component: <CityRedirect />,
                delay: 8,
                waitAction: true,
            },
            {
                id: 'school',
                message: 'Please select the School type',
                trigger: 'schooloptions',
                delay: 1000
            },
            {
                id: 'preschool',
                message: 'Please select the Pre school type',
                trigger: 'preschooloptions',
                delay: 1000
            },
            {
                id: 'tuitions',
                message: 'Please select the Tuition type',
                trigger: 'tuitionsoptions',
                delay: 1000
            },
            {
                id: 'afterschool',
                message: 'Please select the After school activites type',
                trigger: 'afterschooloptions',
                delay: 1000
            },
            {
                id: 'kidsplayarea',
                message: 'Please select the Kids play area type',
                trigger: 'kidsplayareaoptions',
                delay: 1000
            },
            {
                id: 'birthdayevents',
                message: 'Please select the Birthday Events type',
                trigger: 'birthdayoptions',
                delay: 1000
            },
            {
                id: 'learningcamps',
                message: 'Please select the Learning camps type',
                trigger: 'learningcampoptions',
                delay: 1000
            },
            {
                id: 'communitysupport',
                message: 'Please select the Community support type',
                trigger: 'communityoptions',
                delay: 1000
            },

            {
                id: 'schooloptions',
                options: [
                    { value: 'private-schools', label: 'Private school', trigger: 'schoolresult' },
                    { value: 'public-schools', label: 'Public school', trigger: 'schoolresult' },
                    { value: 'boarding-schools', label: 'Boarding School', trigger: 'schoolresult' },
                    { value: 'school-special-needs', label: 'Special Needs', trigger: 'schoolresult' },
                    { value: 'international-schools', label: 'International school', trigger: 'schoolresult' },
                    { value: 'alternative-schools', label: 'Alternative school', trigger: 'schoolresult' },
                ],
            },
            {
                id: 'preschooloptions',
                options: [
                    { value: 'montessori', label: 'Montessori', trigger: 'preschoolresult' },
                    { value: 'kindergarten', label: 'Kinder Garten', trigger: 'preschoolresult' },
                    { value: 'play-group', label: 'Play Group', trigger: 'preschoolresult' },
                    { value: 'special-needs', label: 'Special Needs', trigger: 'preschoolresult' },
                    { value: 'day-care-or-creche', label: 'Day Care and Creche school', trigger: 'preschoolresult' },
                    { value: 'e-schools-pre', label: 'E-School', trigger: 'preschoolresult' },

                ],
            },
            {
                id: 'kidsplayareaoptions',
                options: [
                    { value: 'indoor', label: 'Indoor', trigger: 'kidsplayresult' },
                    { value: 'outdoor', label: 'Outdoor', trigger: 'kidsplayresult' }
                ],
            },
            {
                id: 'learningcampoptions',
                options: [
                    { value: 'curated-learning-camps', label: 'BeWise Curated Camps', trigger: 'learningcampsresult' },
                    { value: 'below-4-years', label: 'Below 4 Years', trigger: 'learningcampsresult' },
                    { value: '5-7-years', label: '5 - 7 Years', trigger: 'learningcampsresult' },
                    { value: '8-10-years', label: '8 - 10 Years', trigger: 'learningcampsresult' },
                    { value: '11-12-years', label: '11 - 12 Years', trigger: 'learningcampsresult' },
                    { value: '13-15-years', label: '13 - 15 Years', trigger: 'learningcampsresult' }
                ],
            },


            {
                id: 'tuitionsoptions',
                options: [
                    { value: 'mathematics', label: 'Mathematics', trigger: 'tuitionresult' },
                    { value: 'science', label: 'Science', trigger: 'tuitionresult' },
                    { value: 'economics', label: 'Economics', trigger: 'tuitionresult' },
                    { value: 'business-studies', label: 'Business Studies', trigger: 'tuitionresult' },
                    { value: 'languages', label: 'Languages', trigger: 'tuitionresult' }
                ],
            },
            {
                id: 'afterschooloptions',
                options: [
                    { value: 'sports', label: 'Sports', trigger: 'afterschoolresult' },
                    { value: 'music', label: 'Music', trigger: 'afterschoolresult' },
                    { value: 'dance-and-theatre', label: 'Dance And Theatre', trigger: 'afterschoolresult' },
                    { value: 'creative-arts', label: 'Creative Arts', trigger: 'afterschoolresult' },
                    { value: 'mind-and-skill-enrichment', label: 'Mind & Skills Enrichment', trigger: 'afterschoolresult' },
                    { value: 'hobbies-and-passion', label: 'Hobbies & Passion', trigger: 'afterschoolresult' },

                ],
            },
            {
                id: 'birthdayoptions',
                options: [
                    { value: 'venues', label: 'Venues', trigger: 'birthdayresult' },
                    { value: 'bakery-or-cake-shops', label: 'Bakery Or Cake Shops', trigger: 'birthdayresult' },
                    { value: 'entertainers-or-mcs', label: 'Entertainers Or MCs', trigger: 'birthdayresult' },
                    { value: 'party-planners', label: 'Party Planners', trigger: 'birthdayresult' },
                    { value: 'gift-and-supportives', label: 'Gifts & Supportives', trigger: 'birthdayresult' }
                ],
            },
            {
                id: 'communityoptions',
                options: [
                    { value: 'counselor', label: 'Counselor', trigger: 'communityresult' },
                    { value: 'articles-blogs', label: 'Articles and Blogs', trigger: 'communityresult' },
                ],
            },

            {
                id: 'kidsplayresult',
                component: <KidsplayareaResult />,
                asMessage: true,
                delay: 1000,
                trigger: 'startover',
            },
            {
                id: 'learningcampsresult',
                component: <LearningCampResult />,
                asMessage: true,
                delay: 1000,
                trigger: 'startover',
            },
            {
                id: 'communityresult',
                component: <CommunityResult />,
                asMessage: true,
                delay: 1000,
                trigger: 'startover',
            },
            {
                id: 'birthdayresult',
                component: <BirthDayResult />,
                asMessage: true,
                delay: 1000,
                trigger: 'startover',
            },

            {
                id: 'preschoolresult',
                component: <PreSchoolResult />,
                asMessage: true,
                delay: 1000,
                trigger: 'startover',
            },
            //after school
            {
                id: 'afterschoolresult',
                component: <AfterSchoolResult />,
                asMessage: true,
                delay: 1000,
                trigger: 'startover',
            },

            {
                id: 'schoolresult',
                component: <SchoolResult />,
                asMessage: true,
                delay: 1000,
                trigger: 'startover',
            },

            {
                id: 'tuitionresult',
                component: <TuitionResult />,
                asMessage: true,
                delay: 1000,
                trigger: 'startover',
            },

            {
                id: 'about',
                component: <About />,
                asMessage: true,
                delay: 1000,
                trigger: 'startover',
            },
            {
                id: 'startover',
                component: (
                    <div> Would you like to look for any other information? </div>
                ),
                asMessage: true,
                //message: 'Would you like to look for any other information?',
                trigger: 'yesorno',
                delay: 1000,
            },
            {
                id: 'yesorno',
                options: [

                    //{ label: 'Contact Us', trigger: ()=>{ $(".clickcontact").trigger('click'); } },
                    { value: 'yes', label: 'YES', trigger: () => { this.setState({ chatbotKey: new Date().getTime() }); } },
                    { value: 'no', label: 'NO', trigger: 'showcontactus' },
                ],
            },
            {
                id: '999',
                message: 'Search using keywords or key phrases',
                trigger: 'search',
            },
            {
                id: 'search',
                user: true,
                trigger: 'searchresult',
            },
            {
                id: 'searchresult',
                component: <SearchResult />,
                asMessage: true,
                trigger: '1000',
            },

            {
                id: 'showcontactus',
                component: (
                    <div>would you like to contact us? <span data-toggle="modal" data-target="#postQueryModal" class="chatbotlink">click here</span></div>
                ),
                asMessage: true,
                trigger: 'byefornow',
            },
            {
                id: 'byefornow',
                message: 'Hope we were helpful. Thank you for visiting BeWise.',
                end: true,
            },
            {
                id: '1000',
                message: 'Hope we were helpful. Thank you for visiting BeWise.',
                end: true,
            },
        ];
        return (
            <div id="chatbotblock" class="hidden">

                   
                <ThemeProvider theme={theme}>
                    <ChatBot key={this.state.chatbotKey}
                        headerTitle="BeWise Chat Support"
                        headerComponent={<HeaderChatbot onChatClose={this.closeChat} />}
                        speechSynthesis={{ enable: false, lang: 'en' }}
                        botAvatar="https://www.bewise.in/assets/images/bewise_logo39x32.png"
                        floating={false}
                        opened={true}
                        steps={steps} {...config} />
                </ThemeProvider>
               
            </div>
        )
    }
    closeChat = () => {
        this.props.onChatClose();
        console.log('this.props')
    }
}
export default ChatBotNew;